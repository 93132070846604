import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import React from 'react';

function RedirectingPage({ url }) {
    let navigate = useNavigate();

    useEffect(() => {
        navigate(url)
    }, []);

    return (
        <div>
        </div>
    );
}

export default RedirectingPage;
